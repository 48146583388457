import { EmblaCarouselType } from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

export const useGetVisibleSliderIndexes = (
    embla: EmblaCarouselType | undefined,
    initialState: number[],
    isAutoplay = false,
    slidersCount = 0,
): number[] => {
    const [visibleSliderIndexes, setVisibleSliderIndexes] = useState<number[]>(initialState);

    useEffect(() => {
        if (!embla) {
            setVisibleSliderIndexes(initialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialState.length]);

    const onSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setVisibleSliderIndexes(emblaApi.slidesInView());
    }, []);

    useEffect(() => {
        if (!embla) {
            return void null;
        }

        embla.on('slidesInView', onSlidesInView);

        return () => {
            embla.off('slidesInView', onSlidesInView);
        };
    }, [embla, onSlidesInView]);

    const firstIndex = isAutoplay && visibleSliderIndexes[0] === 0 ? slidersCount - 1 : visibleSliderIndexes[0] - 1;
    const lastIndex =
        isAutoplay && visibleSliderIndexes[visibleSliderIndexes.length - 1] + 1 === slidersCount
            ? 0
            : visibleSliderIndexes[visibleSliderIndexes.length - 1] + 1;

    return [firstIndex, ...visibleSliderIndexes, lastIndex];
};
