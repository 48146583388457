import SliderProductItem from './ProductsSliderItem';
import { ArrowThinIcon } from 'components/Basic/Icon/IconsSvg';
import { FC, HTMLAttributes, ImgHTMLAttributes, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ListedProductType } from 'types/product';
import useEmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { ProductListMetadata } from 'components/Basic/Head/ProductListMetadata/ProductListMetadata';
import { useElementsMaxHeight } from 'helpers/productList/useElementsMaxHeight';
import { useGetVisibleSliderIndexes } from 'hooks/slider/useGetVisibleSliderIndexes';
import twMerge from 'utils/twMerge';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type ProductsSliderProps = {
    products: ListedProductAccessoriesFragmentApi[] | ListedProductType[];
    isOpenOnMobile?: boolean;
    gtmListName: GtmListNameType;
    gtmMessageOrigin: GtmMessageOriginType;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

const INITIAL_SLIDER_SETTINGS: EmblaOptionsType = {
    containScroll: 'trimSnaps',
    dragFree: false,
    duration: 20,
    align: 'start',
    breakpoints: {
        '(max-width: 599px)': { loop: false, skipSnaps: false },
    },
};

export const ProductsSlider: FC<ProductsSliderProps & NativeProps> = ({
    isOpenOnMobile = true,
    gtmListName,
    products,
    className,
    gtmMessageOrigin,
    loading = 'lazy',
}) => {
    const [watchDrag, setWatchDrag] = useState(true);
    const [emblaRef, emblaApi] = useEmblaCarousel({ ...INITIAL_SLIDER_SETTINGS, watchDrag });
    const [prevBtnDisabled, setPrevButtonEnabled] = useState(true);
    const [nextBtnDisabled, setNextButtonEnabled] = useState(true);
    const itemsRefs = useRef<RefObject<HTMLDivElement>[]>([]);
    const maxHeightOfItem = useElementsMaxHeight(itemsRefs, products.length);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevButtonEnabled(!emblaApi.canScrollPrev());
        setNextButtonEnabled(!emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) {
            return void null;
        }

        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);

        return () => {
            emblaApi.off('reInit', onSelect);
            emblaApi.off('select', onSelect);
        };
    }, [emblaApi, onSelect]);

    useEffect(() => {
        setWatchDrag(!(prevBtnDisabled && nextBtnDisabled));
    }, [prevBtnDisabled, nextBtnDisabled]);

    const visibleSliderIndexes = useGetVisibleSliderIndexes(emblaApi, [0, 1, 2, 3, 4]);

    return (
        <div
            className={twMerge(
                'pointer-events-none relative opacity-0 lg:pointer-events-auto lg:mb-[56px] lg:opacity-100',
                isOpenOnMobile && 'pointer-events-auto mb-5 opacity-100',
                className,
            )}
        >
            <ProductListMetadata products={products} section={gtmListName} />
            <div
                className="relative -mx-5 min-w-full overflow-hidden pl-5 lg:pt-5 xl:m-0 xl:px-0 xl:py-[15px]"
                ref={emblaRef}
            >
                <div className="relative flex h-full xl:mr-[-12.9px]">
                    {products.map((productItemData, index) => (
                        <SliderProductItem
                            key={productItemData.uuid}
                            product={productItemData}
                            gtmListName={gtmListName}
                            listIndex={index}
                            gtmMessageOrigin={gtmMessageOrigin}
                            productAvailabilityRef={itemsRefs.current[index]}
                            productAvailabilityHeight={maxHeightOfItem}
                            isContentVisible={visibleSliderIndexes.includes(index)}
                            loading={loading}
                        />
                    ))}
                </div>
            </div>

            {(!prevBtnDisabled || !nextBtnDisabled) && (
                <div className="absolute right-0 top-1/3 z-above flex w-full items-center justify-center lg:top-[-42px] lg:w-auto">
                    <div
                        className={twMerge(
                            'absolute -left-5 flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-md border border-greyLight bg-white text-creamWhite outline-none ui-transition',
                            'hover:bg-greyDark2',
                            'lg:static lg:ml-[5px] lg:border-greyVeryLight lg:bg-greyVeryLight',
                            prevBtnDisabled && 'cursor-default !bg-white hover:!bg-white [&_svg]:!text-greyLight',
                        )}
                        onClick={scrollPrev}
                    >
                        <ArrowThinIcon className="relative h-[18px] w-[18px] rotate-90 text-base" />
                    </div>
                    <div
                        className={twMerge(
                            'absolute -right-5 flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-md border border-greyLight bg-white text-creamWhite outline-none ui-transition',
                            'hover:bg-greyDark2',
                            'lg:static lg:ml-[5px] lg:border-greyVeryLight lg:bg-greyVeryLight',
                            nextBtnDisabled && 'cursor-default !bg-white hover:!bg-white [&_svg]:!text-greyLight',
                        )}
                        onClick={scrollNext}
                    >
                        <ArrowThinIcon className="relative h-[18px] w-[18px] -rotate-90 text-base" />
                    </div>
                </div>
            )}
        </div>
    );
};

ProductsSlider.displayName = 'ProductsSlider';
