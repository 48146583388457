import { getGtmProductsListEvent } from 'helpers/gtm/eventFactories';
import { gtmSafePushEvent } from 'helpers/gtm/gtm';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useEffect, useRef } from 'react';
import { GtmListNameType } from 'types/gtm/enums';
import { LastVisitedProductType, ListedProductType } from 'types/product';
import { useGtmContext } from '../context/useGtmContext';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';

export const useGtmSliderProductsListEvent = (
    products: ListedProductType[] | LastVisitedProductType[] | ListedProductAccessoriesFragmentApi[] | undefined,
    listName: GtmListNameType,
): void => {
    const wasViewedRef = useRef(false);
    const { url } = useDomainConfig();
    const didPageViewRun = useGtmContext().didPageViewRun;

    useEffect(() => {
        if (didPageViewRun && products !== undefined && products.length !== 0 && !wasViewedRef.current) {
            wasViewedRef.current = true;
            gtmSafePushEvent(getGtmProductsListEvent(products, listName, 1, 0, url));
        }
    }, [listName, products, url, didPageViewRun]);
};
