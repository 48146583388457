import { useEffect, useState, createRef, MutableRefObject, RefObject } from 'react';

export const useElementsMaxHeight = (
    itemsRefs: MutableRefObject<RefObject<HTMLDivElement>[]>,
    totalCount: number,
): number => {
    const [maxHeightOfItem, setMaxHeightOfItem] = useState(0);

    if (itemsRefs.current.length !== totalCount) {
        itemsRefs.current = Array(totalCount)
            .fill([])
            .map(() => createRef());
    }

    useEffect(() => {
        const itemsHeight = itemsRefs.current.reduce((result: number[], item) => {
            if (item.current !== null) {
                result.push(item.current.getBoundingClientRect().height);
            }
            return result;
        }, []);

        setMaxHeightOfItem(Math.max(...itemsHeight));
    }, [itemsRefs, itemsRefs.current.length]);

    return maxHeightOfItem;
};
